/** @jsx jsx */
import { Link } from "gatsby"
import { jsx, Box, Container, Flex, Grid, Heading, Text } from "theme-ui"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import PostCard from "../components/PostCard"
import Button from "../components/Button"
import { BsArrowRight, BsArrowLeft } from "react-icons/bs"

const BlogPostArchiveTemplate = ({
  data,
  pageContext: { nextPagePath, previousPagePath, currentPage, numPages },
}) => {
  const posts = data.allWpPost.nodes

  return (
    <Layout>
      <Box as="section" sx={{ bg: "background.dark2x" }}>
        <Container py={10}>
          <Flex sx={{ flexDirection: "column" }}>
            <Heading as="h1" variant="xxl">
              <Link to="/blog/">Blog</Link>
            </Heading>
            {currentPage !== 1 && (
              <Text sx={{ mt: 3, color: "text.light2x" }}>
                Page {currentPage} of {numPages}
              </Text>
            )}
          </Flex>
        </Container>
      </Box>

      <Container as="section" py={10}>
        <Grid
          sx={{
            gridTemplateColumns: ["1fr", "1fr 1fr", null, "repeat(3, 1fr)"],
            gridGap: "64px 40px",
          }}
        >
          {posts.map((post, index) => {
            return <PostCard key={post.id} post={post} />
          })}
        </Grid>
      </Container>
      <Container pb={10}>
        <Flex sx={{ justifyContent: "space-between", position: "relative" }}>
          {previousPagePath ? (
            <Button
              as="internal_link"
              url={previousPagePath}
              variant="secondary"
            >
              <BsArrowLeft
                strokeWidth="1"
                sx={{
                  mr: 2,
                }}
              />
              Newer Posts
            </Button>
          ) : (
            <span></span>
          )}
          <Text sx={{ color: "text.light2x", textAlign: "center", px: 3 }}>
            Page {currentPage} of {numPages}
          </Text>
          {nextPagePath ? (
            <Button as="internal_link" url={nextPagePath} variant="secondary">
              Older Posts
              <BsArrowRight
                strokeWidth="1"
                sx={{
                  ml: 2,
                }}
              />
            </Button>
          ) : (
            <span></span>
          )}
        </Flex>
      </Container>
    </Layout>
  )
}

export default BlogPostArchiveTemplate

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        ...WpPostCardFields
      }
    }
    latestPost: allWpPost(limit: 1, sort: { fields: [date], order: DESC }) {
      nodes {
        ...WpPostCardFields
        featuredImageLarge: featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 900, placeholder: BLURRED)
              }
            }
            altText
          }
        }
      }
    }
  }
`
